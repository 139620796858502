import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const RenewableEnergy = () => (
  <Layout>
    <SEO title="Renewable Energy" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Renewable Energy</h1>
        <p>
          The future is here, and Solar and Wind energy are leading the way! Our
          experience working on solar and wind projects for the industry leaders
          in this space, has reinforced the importance of building long-lasting
          relationships with the shareholders we negotiate and work with on our
          client’s behalf. These long-lasting relationships are crucial to
          seeing your project come to a successful conclusion. Gray Hawk Land
          Solution’s experience in the land acquisition business can meet &
          exceed the needs & expectations that our Solar and Wind clients have
          placed on our shoulders. Our clients rely on our experience in
          providing and staffing land acquisition professionals to deliver
          professional results. We have the ability to staff any sized solar or
          wind project in any part of the country, no matter the size of your
          project. Trust is what we have built with all shareholders and in the
          communities where we have worked representing our clients. Our
          reputation carries with it - honesty, trustworthiness, and
          professionalism that our clients can leverage to achieve successful
          results to their wind and solar projects.
        </p>
        <h2 className="my-4">Services Offered</h2>
        <div className="row mt-5">
          <div className="col-md-6 col-lg-4">
            <ul>
              <li>Condemnation Support</li>
              <li>Crossing Permit Acquisition</li>
              <li>Preparation of Exhibit Maps</li>
              <li>Project Management</li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-4">
            <ul>
              <li>Records Management</li>
              <li>Right-of-Way and Site Acquisitions</li>
              <li>Route Selection and Site Studies</li>
              <li>Title Research and Analysis</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default RenewableEnergy
